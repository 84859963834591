import { render, staticRenderFns } from "./OrderFormPdf.vue?vue&type=template&id=70525634&scoped=true"
import script from "./OrderFormPdf.vue?vue&type=script&lang=js"
export * from "./OrderFormPdf.vue?vue&type=script&lang=js"
import style0 from "./OrderFormPdf.vue?vue&type=style&index=0&id=70525634&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70525634",
  null
  
)

export default component.exports